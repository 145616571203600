import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from './components/Layout';
import { HeaderSection } from './components/HeaderSection';
import { IntroSection } from './components/IntroSection';
import { Promo } from './components/Promo';
import { FeaturesSection } from './components/FeaturesSection';
import { PriceSection } from './components/PriceSection';
import { ClientTestimonials } from './components/ClientTestimonials';
import { ScheduleDemoSection } from './components/ScheduleDemoSection';
import { DeveloperHelpSection } from './components/DeveloperHelpSection';
import { FAQSection } from './components/FAQSection';
import { FooterSection } from './components/FooterSection';

const MainContainer = () => {
  return (
    <Layout>

      <Helmet>
        <title>Bill Orbit | Best School Fee Management & Payment Software</title>
        
        <meta name='description' content='Bill Orbit offers a state-of-the-art school fee management solution that streamlines fee collection, enhances student management, and simplifies school administration—all in one automated platform.'/>
        <meta name='keywords' content='school fee management software, fee collection, student management, school administration, automated fee system, Bill Orbit'/>
        
        <meta property='og:title' content='Bill Orbit | Best School Fee Management & Payment Software'/>
        <meta property='og:description' content='Experience seamless fee collection, efficient student management, and simplified school administration with Bill Orbit’s automated fee management system.'/>
        <meta property='og:image' content='https://dev.billorbit.com/assets/images/og_imageschool_landingpage_1200x630.jpg?v=2'/>
        <meta property='og:url' content='https://billorbit.com/'/>
        <meta property='og:type' content='website'/>

        <meta name='twitter:card' content='summary_large_image'/>
        <meta name='twitter:title' content='Bill Orbit | Best School Fee Management & Payment Software'/>
        <meta name='twitter:description'content='Discover an all-in-one automated fee management solution designed for modern schools. Boost efficiency and streamline school operations with Bill Orbit.'/>
        <meta name='twitter:image' content='https://dev.billorbit.com/assets/images/og_imageschool_landingpage_1200x630.jpg'/>
      </Helmet>


      {/* Use single quotes in JSX attributes below */}
      <HeaderSection classOption='navbar-light' />
      <IntroSection />
      <Promo />
      <FeaturesSection />
      <PriceSection haspriceTitleWithHeader paddingTop='pt-60 pb-120' />
      <ClientTestimonials />
      <ScheduleDemoSection />
      <DeveloperHelpSection />
      <FAQSection />
      <FooterSection
        style={{
          background: "url('/assets/images/page-header-bg.svg') no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default MainContainer;
