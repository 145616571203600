import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HeaderSection } from './HeaderSection';
import { FooterSection } from './FooterSection';
import { Link } from 'react-router-dom'
import { SectionTitle } from './SectionTitle'
import ProductInquiryForm from './ProductInquiryForm'
import 'react-toastify/dist/ReactToastify.css';


function PromoGymPosPay() {
  

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '8px',
      margin: '0 auto',
      minHeight: '100vh',
      justifyContent: 'space-between',
    },
    video: {
      width: '100%',
      maxWidth: '900px', 
      height: 'auto',
      borderRadius: '20px',
      marginBottom: '30px',
      marginTop: '100px',
      padding: '10px'
    },
    footer: {
      width: '100%',
      backgroundColor: '#f1f1f1',
      marginTop: '50px',
    },
    textSection: {
      textAlign: 'center',
      marginBottom: '30px',
      marginTop: '40px',
      padding: '0 20px', 
    },
  };

  return (
    <div style={styles.container}>
      <Helmet>
        <title> One-stop Automated Gym Fee Membership Management Software !</title>
        <meta property='og:title' content='Billorbit | Gym Demo - Point Of Sale!' />
        <meta property='og:description' content='Streamline your Gym Memberships, Fee Collections and Payments for a stress-free gym management experience. Get Onboarded and Enable the simplest payment experience.' />
        <meta property='og:image' content='https://dev.billorbit.com/assets/images/og_imagegym_pos_1200x630.jpg' />
        <meta property='og:url' content='https://billorbit.com' />
      </Helmet>
      <HeaderSection />

      {/* Video Section */}
      <video style={styles.video} controls>
        <source
          src={`${process.env.PUBLIC_URL}/assets/images/DemoGym.mp4`}
          type='video/mp4'
        />
      </video>

      {/* Text Section  */}
      <div style={{ textAlign: 'center', marginBottom: '30px', marginTop: '60px' }}>
        {/* Schedule Free Demo */}
        <h2 style={{ color: 'blue', fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
          Schedule Free Demo
        </h2>

        {/* Request Today */}
        <h3 style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '15px' }}>
          Request Today! Get Your Free Demo
        </h3>

        {/* Informational Paragraph */}
        <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333', maxWidth: '800px', margin: '0 auto',padding: '5px' }}>
        If you don't have an efficient Gym Membership Management system, you will waste valuable time tracking and managing members and payments. It's time to give your gym a smarter way to monitor and manage memberships effortlessly. By investing in the right Recurring Membership Management platform, you'll gain better control over all your membership operations while responding quickly to changes in a dynamic environment.
        </p>
      </div>

      <section
        id='productInquirySection'
        className='contact-us ptb-120 position-relative overflow-hidden'
      >
        <div className='container'>
          <div className='row justify-content-between'>
          <div className='col-xl-5 col-lg-5 col-md-12' style={{ paddingLeft: '30px' }}>
              <SectionTitle
                subtitle='Product Inquiry'
                title='Get in Touch Today!'
                description='Proactively deliver seamless core competencies with
                    scalable. Completely fabricate transparent paradigms.'
              />
              <div className='row justify-content-between pb-5'>
                <div
                  className='col-sm-6 mb-4 mb-md-0 mb-lg-0'
                >
                  <div className='icon-box d-inline-block rounded-circle bg-primary-soft'>
                    <i className='fas fa-phone fa-2x text-primary'></i>
                  </div>
                  <div className='contact-info'>
                    <h5>Call Us</h5>
                    <p>
                      Questions about our product or services? Call for support
                    </p>
                    <a
                      href='tel:(+91)8527048080'
                      className='read-more-link text-decoration-none'
                    >
                      <i className='far fa-phone me-2'></i> (+91)8527048080
                    </a>
                  </div>
                </div>
                <div
                  className='col-sm-6'
                >
                  <div className='icon-box d-inline-block rounded-circle bg-danger-soft'>
                    <i className='fas fa-comment-alt-lines fa-2x text-danger'></i>
                  </div>
                  <div className='contact-info'>
                    <h5>Email Us</h5>
                    <p>
                      Our support will help you from
                      <strong> 9am to 9pm IST.</strong>
                    </p>
                    <Link
                      to='/contact-us'
                      className='read-more-link text-decoration-none'
                    >
                      <i className='far fa-comment-alt-dots me-2'></i> sales@goorbit.in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <ProductInquiryForm />
          </div>
        </div>
        <div
          className='bg-dark position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5'
          style={{
            background:
              "url('assets/images/dot-dot-wave-shape.svg')no-repeat center top",
          }}
        >
          <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5'></div>
        </div>
      </section>

      {/* Footer Section */}
      <div style={styles.footer}>
        <FooterSection />
      </div>
    </div>
  );
}

export default PromoGymPosPay;