import React from 'react';
import Routers from './routers';
import { HelmetProvider } from 'react-helmet-async';

// Swiper CSS
import 'swiper/css';
import 'swiper/css/navigation';
import './App.css';

//bootstrap
import 'bootstrap/dist/js/bootstrap.bundle';

function App() {
  return (
    <HelmetProvider>
    <Routers />
  </HelmetProvider>
  );
}

export default App;
