import React from 'react';
import { NotFoundScreen } from '../container/components/NotFoundScreen';
import MainContainer from '../container/MainContainer';
import ContactUsContainer from '../container/ContactUsContainer'
import PrivacyPolicyContainer from '../container/PrivacyPolicyContainer'
import TermsAndConditionContainer from '../container/TermsAndConditionContainer'
import ShippingContainer from '../container/ShippingContainer'
import CancellationAndRefundContainer from '../container/CancellationAndRefundContainer'
import PromoGymUpiLink from '../container/components/PromoGymUpiLink';
import PromoSchoolUpiLink from '../container/components/PromoSchoolUpiLink';
import PromoGymPosPay from '../container/components/PromoGymPosPay';
import PromoSchoolPosPay from '../container/components/PromoSchoolPosPay';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainContainer />,
    errorElement: <NotFoundScreen />,
  },{
    path: '/contactus',
    element: <ContactUsContainer />,
    errorElement: <NotFoundScreen />,
  },{
    path: '/privacypolicy',
    element: <PrivacyPolicyContainer />,
    errorElement: <NotFoundScreen />,
  },{
    path: '/termsandcondition',
    element: <TermsAndConditionContainer />,
    errorElement: <NotFoundScreen />,
  },{
    path: '/shipping',
    element: <ShippingContainer />,
    errorElement: <NotFoundScreen />,
  },{
    path: '/cancelandrefund',
    element: <CancellationAndRefundContainer />,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/gymdemowa',
    element: < PromoGymUpiLink/>,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/schooldemowa',
    element: < PromoSchoolUpiLink/>,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/gymdemopos',
    element: < PromoGymPosPay/>,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/schooldemopos',
    element: < PromoSchoolPosPay/>,
    errorElement: <NotFoundScreen />,
  },
]);

function index() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default index;
