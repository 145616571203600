import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from './Modal/Modal';

export const OffCanvasMenu = ({
  setShow,
  show,
  redirectToLogin,
  setTanentName,
  tanentName,
}) => {
  const smoothScroll = (ele) => {
    ele.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const ScrollToHome = () => {
    let home = document.getElementById('home');
    smoothScroll(home);
  };

  const ScrollToFeatures = () => {
    let features = document.getElementById('features-section');
    smoothScroll(features);
  };
  const ScrollToPricing = () => {
    let pricing = document.getElementById('pricing-section');
    smoothScroll(pricing);
  };

  const ScrollToTestimonials = () => {
    let testimonials = document.getElementById('testimonials-section');
    smoothScroll(testimonials);
  };

  const ScrollToFAQs = () => {
    let faqs = document.getElementById('FAQs-section');
    smoothScroll(faqs);
  };

  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasWithBackdrop'
      >
        <div className='offcanvas-header d-flex align-items-center mt-4'>
          <Link
            to='/'
            className='d-flex align-items-center mb-md-0 text-decoration-none bill-orbit-logo'
          >
            <img
              src='/assets/images/logo.png'
              alt='logo'
              className='img-fluid ps-2'
            />
          </Link>
          <button
            type='button'
            className='close-btn text-danger'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          >
            <i className='far fa-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
            <li>
              <Link to='/' className='nav-link' onClick={ScrollToHome}>
                Home
              </Link>
            </li>
            <li>
              <Link to='/' className='nav-link' onClick={ScrollToFeatures}>
                Features
              </Link>
            </li>
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle'
                to='/'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Solution
              </Link>
              <div className='dropdown-menu border-0 rounded-custom shadow py-0 bg-white'>
                <div className='dropdown-grid rounded-custom width-full'>
                  <div className='dropdown-grid-item'>
                    <Link to='/' className='dropdown-link'>
                      <span className='demo-list bg-primary rounded text-white fw-bold'>
                        1
                      </span>
                      <div className='dropdown-info'>
                        <div className='drop-title'>GYM</div>
                        <p>
                          It's for <strong>GYMs</strong>
                        </p>
                      </div>
                    </Link>
                    <Link to='/' className='dropdown-link'>
                      <span className='demo-list bg-primary rounded text-white fw-bold'>
                        2
                      </span>
                      <div className='dropdown-info'>
                        <div className='drop-title'>School</div>
                        <p>
                          Modern <strong>Schools</strong>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to='/' className='nav-link' onClick={ScrollToPricing}>
                Pricing
              </Link>
            </li>
            <li>
              <Link to='/' className='nav-link' onClick={ScrollToTestimonials}>
                Testimonials
              </Link>
            </li>
            <li>
              <Link to='/' className='nav-link'>
                Schedule Free Demo
              </Link>
            </li>
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle'
                to='/'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Resources
              </Link>
              <div className='dropdown-menu border-0 rounded-custom shadow py-0 bg-white'>
                <div className='dropdown-grid rounded-custom width-full'>
                  <div className='dropdown-grid-item'>
                    <Link to='/' className='dropdown-link'>
                      <span className='demo-list bg-primary rounded text-white fw-bold'>
                        1
                      </span>
                      <div className='dropdown-info'>
                        <div className='drop-title'>Support</div>
                      </div>
                    </Link>
                    <Link to='/' className='dropdown-link'>
                      <span className='demo-list bg-primary rounded text-white fw-bold'>
                        2
                      </span>
                      <div className='dropdown-info'>
                        <div className='drop-title'>Help</div>
                      </div>
                    </Link>
                    <Link to='/' className='dropdown-link'>
                      <span className='demo-list bg-primary rounded text-white fw-bold'>
                        3
                      </span>
                      <div className='dropdown-info'>
                        <div className='drop-title'>Blog</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to='/' className='nav-link' onClick={ScrollToFAQs}>
                FAQ
              </Link>
            </li>
          </ul>
          <div className='action-btns mt-4 ps-3'>
            <button onClick={() => setShow(true)} className='btn btn-primary'>
              Login
            </button>
            <Modal
              title='Tanent Login'
              onClose={() => setShow(false)}
              onLoginClick={() => redirectToLogin()}
              show={show}
            >
              <div className='col'>
                <label htmlFor='phone' className='mb-1'>
                  Tanent Name
                </label>
                <div className='input-group mb-3'>
                  <input
                    onChange={(e) => setTanentName(e.target.value)}
                    type='text'
                    value={tanentName}
                    className='form-control'
                    placeholder='Tanent Name'
                    aria-label='Tanent Name'
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
