import React from 'react';
import { SectionTitle } from './SectionTitle';
import { ContactUsForm } from './ContactUsForm';

export const ScheduleDemoSection = ({ darkBg }) => {
  return (
    <>
      <section
        className={`customer-review-tab ${
          darkBg ? 'bg-gradient text-white' : 'bg-light'
        } position-relative z-2`}
      >
        <div className='container'>
          <div className='row justify-content-center align-content-center'>
            <div className='col-md-10 col-lg-6'>
              {darkBg ? (
                <SectionTitle
                  title='Request Today! Get Your Free Demo Tomorrow'
                  subtitle='Schedule Free Demo'
                  description="If you don't have an efficient Tution Fee Management system, you will be wasting lots of time tracking and managing Student's Dues. It's time to give your Institute a smarter way to track and manage Student's Dues. By investing in the right Recurring Fee Management platform, you'll increase control over all your Fee Management needs while responding more quickly to changes in dynamic environment."
                  dark
                  centerAlign
                />
              ) : (
                <SectionTitle
                  title='Request Today! Get Your Free Demo Tomorrow'
                  subtitle='Schedule Free Demo'
                  description="If you don't have an efficient Tution Fee Management system, you will be wasting lots of time tracking and managing Student's Dues. It's time to give your Institute a smarter way to track and manage Student's Dues. By investing in the right Recurring Fee Management platform, you'll increase control over all your Fee Management needs while responding more quickly to changes in dynamic environment."
                  centerAlign
                />
              )}
            </div>
          </div>
          <div
            id='request-demo-section'
            className='pt-3 row justify-content-center align-content-center'>
            <div className='col-12'>
              <ul
                className='nav nav-pills testimonial-tab-menu mt-60'
                id='demo'
                role='tablist'
              >
                <li className='nav-item' role='presentation'>
                  <div
                    id='requestFreeDemo'
                    className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active'
                    data-bs-toggle='pill'
                    data-bs-target='#demo-tab-1'
                    role='tab'
                    aria-selected='false'
                  >
                    <div className='author-info'>
                      <h6 className='mb-0'>Request Free Demo</h6>
                    </div>
                  </div>
                </li>
                <li className='nav-item' role='presentation'>
                  <div
                    id='requestOnboarding'
                    className='nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link'
                    data-bs-toggle='pill'
                    data-bs-target='#demo-tab-2'
                    role='tab'
                    aria-selected='false'
                  >
                    <div className='author-info'>
                      <h6 className='mb-0'>Request Onboarding</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='tab-content' id='demo-tabContent'>
                <div
                  className='tab-pane fade active show'
                  id='demo-tab-1'
                  role='tabpanel'
                >
                  {/* TODO change the type here */}
                  <ContactUsForm type='demo' />
                </div>
                <div className='tab-pane fade' id='demo-tab-2' role='tabpanel'>
                  <ContactUsForm type='lead' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
